import styled from "styled-components"
import breakpoints from "./breakpoints"
const StyledInternalPageNav = styled.nav`
	padding: 1.6rem;
    ul {
        list-style-type: none;
        padding: 0;
        li {
            display: inline-block;
            margin: 1.6rem 0;
            @media screen and (min-width: ${breakpoints.sm}px) {
                margin: 1.6rem 1.6rem 1.6rem 0;
            }
            a {
                padding: 1.2rem;
                background: var(--grey-lightest);
                
            }
        }
    }
`

export {
    StyledInternalPageNav
}