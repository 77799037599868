import React from "react"
import { StyledInternalPageNav } from "../styledComponents/internalPageNavigation"

const WpSubNav = () => {
    return (

        <StyledInternalPageNav>
            <h2>Our WordPress Services</h2>
            <ul>
                <li><a href="/services/wordpress/support-maintenance">Wordpress Support &amp; Maintenance</a></li>
                <li><a href="/services/wordpress/website-design">Wordpress Website Design</a></li>
                <li><a href="/services/wordpress/development">Wordpress Website Development</a></li>
                <li><a href="/services/wordpress/hosting">Wordpress Website Hosting</a></li>
                <li><a href="/services/wordpress/audit">Wordpress Website Audit</a></li>
            </ul>
        </StyledInternalPageNav>

    )
}

export default WpSubNav