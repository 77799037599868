import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../../../components/layout"
import Seo from "../../../components/seo"
import { Article, ArticleSection } from "../../../styledComponents/article"
import { HeroTextImageWrapper, HeroText, HeroHeader, HeroDescription } from "../../../styledComponents/heroBanner"
import Icon from "../../../components/icon"
import WpSubNav from "../../../components/wordpressSubNavigation"


const WordPressIndex = ({ data, location, createCookie }) => {

  return (
    <Layout location={location}>
      <Seo
        title="Wordpress Support & Maintenance"
        description="WordPress Support. WordPress Support & Maintenance plans. Expert technical support, website maintenance & managed WordPress hosting."
        image=""
        url=""
      />
      <HeroTextImageWrapper backgroundColor="var(--orange-mid)" gridRowGap="0">
        <StaticImage
          layout="fullWidth"
          // // You can optionally force an aspect ratio for the generated image
          // aspectRatio={1 / 1}
          // // This is a presentational image, so the alt should be an empty string
          // alt=""
          // Assisi, Perúgia, Itália by Bernardo Ferrari, via Unsplash
          src={
            "../../../images/wordpress.png"
          }
          formats={["auto", "webp", "avif"]}
        />
        <HeroText fontColor="var(--white)">
          <HeroHeader level={1}>
            WordPress Website Support & Maintenance Services
          </HeroHeader>
          <HeroDescription>Experience reliable UK based Support, Maintenance and Premium WordPress Hosting from a dedicated technical WordPress support agency. </HeroDescription>
        </HeroText>
      </HeroTextImageWrapper>
      <WpSubNav />
      <Article>
        <ArticleSection isList>
          <h2>Our WordPress Support plans are designed to provide a holistic solution to your website’s performance, reliability and security.</h2>
          <p>Websites can demand a lot of attention in order to keep them updated, fast, secure and functional.</p>
          <p>That’s where we come in.</p>
          <p>Our support plans let you hand all of this over to our experts. Giving you back your time and the confidence that your website is being managed by WordPress professionals.</p>
          <p>Delivered by proactive and responsive WordPress specialists, our plans encompass support and ongoing maintenance. Alongside premium managed hosting, optimised for WordPress.</p>
          <p>Get total peace of mind with our support plans. Our team are ready to help and just a call away.</p>

          <h3>Our process includes:</h3>
          <ul className="process-ticks">
            <li><Icon name="circle-tick" />Routine maintenance</li>
            <li><Icon name="circle-tick" />Speed &amp; Performance optimisation</li>
            <li><Icon name="circle-tick" />SLA reactive technical support</li>
            <li><Icon name="circle-tick" />Enterprise level hosting</li>
            <li><Icon name="circle-tick" />High quality security firewall</li>
            <li><Icon name="circle-tick" />Custom support plans available</li>
          </ul>

          <StaticImage src="../../../images/ux-design-process.jpg" className="article-inline-image" />

          <h2>We Created The Ultimate Combined WordPress Hosting, Support & Maintenance Solution That Our Clients Love</h2>
          <p>Having provided support, maintenance and managed hosting to businesses for many years, we understand the main drivers for website success.
            Speed, security and reliability.</p>

          <p>Our Support Unlimited WordPress Care Plans are built around exactly that.</p>


        </ArticleSection>
      </Article>
    </Layout>
  )
}

export default WordPressIndex
